import { useTheme } from "@mui/material";
import { useMetrics, usePlatformVulnerabilityConfiguration } from "~/hooks";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { getColor } from "~/lib/colors";
import { pluralize } from "~/lib/pluralize";
import { Space } from "~/lib/types";
import {
  Asset,
  useGetVulnerabilityScoresQuery,
  VulnerabilityScoreType,
} from "~/operations";
import { Metrics } from "~/utils/arrow";

type UseAssetInsightsProps = {
  assetMrn: string;
  space: Space;
  scope: SpaceOrWorkspaceScope;
  insights: Asset["insights"];
  exceptionsCount: number;
  isEol: boolean;
};

type AssetInsightsItem = {
  title: string;
  grade: string;
  policyCount: string;
  color: string;
  disabled: boolean;
  to?: string;
};

const HYPHEN = "–";

export function useAssetInsights({
  assetMrn,
  insights,
  space,
  scope,
  exceptionsCount,
  isEol,
}: UseAssetInsightsProps) {
  const { pvcIsEnabled } = usePlatformVulnerabilityConfiguration({ space });
  const theme = useTheme();

  const { checksResults } = useMetrics({
    entityMrn: assetMrn,
    metricMrns: [Metrics.ChecksResultDistribution],
  });

  const { data: vulnScoresData } = useGetVulnerabilityScoresQuery({
    variables: {
      entityMrn: assetMrn,
      first: 1,
      filter: { scoreType: VulnerabilityScoreType.Cve },
    },
  });
  const vulnScoresUnion = vulnScoresData?.vulnerabilityScores;
  const vulnsResults =
    vulnScoresUnion?.__typename === "VulnerabilityScoresConnection"
      ? vulnScoresUnion
      : undefined;

  const { critical, high } = checksResults;
  const priorityFindingsCount = critical.total + high.total;
  const cvesCount = vulnsResults?.totalCount || 0;

  const insightHref = (key: string) => {
    const paths: Record<string, string | null> = {
      security: "checks",
      vulnerability: "vulnerabilities",
      exceptions: "exceptions",
    };
    const params: Record<string, URLSearchParams | null> = {
      security: new URLSearchParams({ impact: "critical,high" }),
    };
    const path = paths[key];
    const searchParams = new URLSearchParams([
      ...scope.params,
      ...(params[key] || []),
    ]);
    if (path) {
      return `${path}?${searchParams}`;
    }
  };

  // extract typename from insights
  const { __typename, ...insightsData } = insights || {};
  const assetInsights: AssetInsightsItem[] = Object.entries(insightsData).map(
    ([key, value]) => {
      // check if the insight is disabled
      // if it's a vulnerability insight, check if the PVC is enabled
      // if it's a security insight, check if there are any policies
      const getDisabledStatus = () => {
        if (key === "vulnerability" && !value) {
          return true;
        }
        if (value?.__typename === "AssetInsightsVulnerability") {
          return !pvcIsEnabled;
        }
        if (value?.__typename === "AssetInsightsSecurity") {
          return priorityFindingsCount === 0;
        }
        return false;
      };

      const disabledInsight = getDisabledStatus();

      const label =
        value?.__typename === "AssetInsightsSecurity"
          ? "Priority finding"
          : "CVE";
      const count =
        value?.__typename === "AssetInsightsSecurity"
          ? priorityFindingsCount
          : cvesCount;
      const policyCountLabel = `${count} ${pluralize(label, count)}`;

      // get the color for the insight
      // if the insight is disabled, set the color to "disabled"
      const color = disabledInsight
        ? getColor(theme, "disabled")
        : getColor(theme, value?.score?.grade || "unknown");

      const getGrade = () => {
        if (!value) return "N";

        if (
          value.__typename === "AssetInsightsVulnerability" &&
          disabledInsight
        ) {
          return HYPHEN;
        }

        return value.score.grade;
      };

      const grade = getGrade();

      return {
        title: pluralize(key, 2),
        grade,
        policyCount: policyCountLabel,
        color,
        disabled: disabledInsight,
        to: insightHref(key),
      };
    },
  );

  // Manually add the exceptions insight until this is calculated in the backend
  assetInsights.push({
    title: "exceptions",
    grade: HYPHEN,
    policyCount: `${exceptionsCount} ${pluralize(
      "Exception",
      exceptionsCount,
    )}`,
    color: getColor(theme, "U"),
    disabled: false,
    to: insightHref("exceptions"),
  });

  return {
    insightsData: assetInsights,
  };
}
