import { tableFromIPC } from "apache-arrow";
import { ChartData } from "~/components/charts/donut-chart";
import { getColor } from "~/lib/colors";
import { useTheme } from "@mui/material";
import { SmallDonutData } from "~/components/SmallDonut";
import { GridData } from "~/pages/space/Dashboards/components/GridSegment/GridSegment";

export enum Metrics {
  // provides a count of policies by category for a given asset or space
  PolicyCategoryDistribution = "//metrics.api.mondoo.app/categories/policies",
  // provides a distribution of policy grades for a given asset or space. For spaces, this is the distribution of the average grades
  PolicyGradeDistribution = "//metrics.api.mondoo.app/distribution/policies",
  // provides a distribution of check severities for a given asset or space
  ChecksResultDistribution = "//metrics.api.mondoo.app/distribution/checks",
  // provides a count of vulnerable assets by severity for a given space
  VulnerableAssetsSeverity = "//metrics.api.mondoo.app/severity/assets/vulns",
  // provides a count of vulnerabilities by severity for a given space
  AdvisoriesSeverity = "//metrics.api.mondoo.app/severity/advisories",
  // provides a count of vulnerabilities by severity for a given space
  CVEsSeverity = "//metrics.api.mondoo.app/severity/vulns",
  // provides stats of remediation severities for a given space
  RemediationSeverity = "//metrics.api.mondoo.app/severity/remediation",
  // provides stats of remediation severities for a given space
  MTTRSeverity = "//metrics.api.mondoo.app/severity/remediation",
  // provides a count of assets by grade in a given space
  SecurityAssetsStats = "//metrics.api.mondoo.app/severity/assets/security",
}

type PolicyCategory = {
  category: string;
  count: number;
};

type PolicyGrade = {
  grade: "a" | "b" | "c" | "d" | "f" | "errored" | "unrated";
  count: number;
};

type Severity = "none" | "low" | "medium" | "high" | "critical";

type CheckResult = {
  errored: number;
  failing: number;
  passing: number;
  unscored: number;
  skipped: number;
  total: number;
  severity: Severity;
};

const defaultCheckResult: Omit<CheckResult, "severity"> = {
  errored: 0,
  failing: 0,
  passing: 0,
  unscored: 0,
  skipped: 0,
  total: 0,
};

type VulnerableAssetSeverity = {
  severity: Severity;
  count: number;
};

type AdvisorySeverity = {
  severity: Severity;
  count: number;
};

type CVESeverity = {
  severity: Severity;
  count: number;
};

type RemediationSeverity = {
  fixed: number;
  mttr_seconds: number;
  severity: Severity;
  total: number;
};

export type MttrObject = {
  [category: string]: {
    days: number;
    hours: number;
    minutes: number;
  };
};

type CheckResultType = {
  errored: number;
  failing: number;
  passing: number;
  severity: Severity;
  skipped: number;
  total: number;
  unscored: number;
};

export function convertArrowStringToArray<T>(arrowString: string): Array<T> {
  const binaryData = Uint8Array.from(atob(arrowString || ""), (c) =>
    c.charCodeAt(0),
  );

  const tableData = tableFromIPC(binaryData).toArray();
  return tableData.map((e) => {
    return e.toJSON();
  });
}

type MetricsEntries = Array<{
  metricMrn: string;
  title: string;
  arrowResult?: string | null;
}>;

function getBaseMetrics<T>(entries: MetricsEntries, metrics: Metrics) {
  const metricsEntry = entries?.find((m) => m.metricMrn === metrics);

  if (!metricsEntry) return [];

  return convertArrowStringToArray<T>(metricsEntry.arrowResult || "");
}

export function parsePolicyCategories(entries: MetricsEntries) {
  const policyCategories = getBaseMetrics<PolicyCategory>(
    entries,
    Metrics.PolicyCategoryDistribution,
  );

  return policyCategories.map((c) => ({
    title: c.category,
    score: c.count,
  }));
}

export function parsePolicyGrades(entries: MetricsEntries): Array<ChartData> {
  const theme = useTheme();
  const policyGradesMetrics = getBaseMetrics<PolicyGrade>(
    entries,
    Metrics.PolicyGradeDistribution,
  );

  return policyGradesMetrics.map((gradeMetrics) => {
    const grade = gradeMetrics.grade.toUpperCase();

    if (grade === "ERRORED") {
      return {
        label: "ERROR",
        value: gradeMetrics.count,
        color: getColor(theme, "error"),
      };
    }

    return {
      label: grade,
      value: gradeMetrics.count,
      color: getColor(theme, grade),
    };
  });
}

function parseChecksResults(
  entries: MetricsEntries,
): Record<Severity, CheckResult> {
  const checkResults = getBaseMetrics<CheckResult>(
    entries,
    Metrics.ChecksResultDistribution,
  );

  return checkResults.reduce<Record<Severity, CheckResult>>(
    (acc, item) => {
      return {
        ...acc,
        [item.severity]: item,
      };
    },
    {
      critical: {
        ...defaultCheckResult,
        severity: "critical",
      },
      high: {
        ...defaultCheckResult,
        severity: "high",
      },
      medium: {
        ...defaultCheckResult,
        severity: "medium",
      },
      low: {
        ...defaultCheckResult,
        severity: "low",
      },
      none: {
        ...defaultCheckResult,
        severity: "none",
      },
    },
  );
}

function parseVulnerableAssetsSeverity(
  entries: MetricsEntries,
): SmallDonutData {
  const vulnerableAssetSeverities = getBaseMetrics<VulnerableAssetSeverity>(
    entries,
    Metrics.VulnerableAssetsSeverity,
  );

  const total = vulnerableAssetSeverities.reduce((acc, current) => {
    return acc + current.count;
  }, 0);

  return {
    total,
    context: vulnerableAssetSeverities.map((s) => ({
      impact: s.severity,
      value: s.count,
    })),
  };
}

function parseAdvisoriesSeverity(entries: MetricsEntries): SmallDonutData {
  const advisoriesSeverities = getBaseMetrics<AdvisorySeverity>(
    entries,
    Metrics.AdvisoriesSeverity,
  );

  const total = advisoriesSeverities.reduce((acc, current) => {
    return acc + current.count;
  }, 0);

  return {
    total,
    context: advisoriesSeverities.map((s) => ({
      impact: s.severity,
      value: s.count,
    })),
  };
}

function parseCVEsSeverity(entries: MetricsEntries): SmallDonutData {
  const cvesSeverities = getBaseMetrics<CVESeverity>(
    entries,
    Metrics.CVEsSeverity,
  );

  const total = cvesSeverities.reduce((acc, current) => {
    return acc + current.count;
  }, 0);

  return {
    total,
    context: cvesSeverities.map((s) => ({
      impact: s.severity,
      value: s.count,
    })),
  };
}

function parseRemediationSeverity(entries: MetricsEntries): Array<GridData> {
  const remediationSeverity = getBaseMetrics<RemediationSeverity>(
    entries,
    Metrics.RemediationSeverity,
  );

  return remediationSeverity.map((c) => ({
    title: c.severity,
    score: c.fixed,
    total: c.total,
  }));
}

function parseMTTRSeverity(entries: MetricsEntries): MttrObject {
  const mttrSeverity = getBaseMetrics<RemediationSeverity>(
    entries,
    Metrics.MTTRSeverity,
  );

  const defaultData: MttrObject = {
    none: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    low: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    medium: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    high: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    critical: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
  };

  return mttrSeverity.reduce((acc, data) => {
    const SECONDS_IN_MINUTE = 60n;
    const SECONDS_IN_HOUR = 60n * SECONDS_IN_MINUTE;
    const SECONDS_IN_DAY = 24n * SECONDS_IN_HOUR;

    // Calculate the number of days
    const days = BigInt(data.mttr_seconds) / SECONDS_IN_DAY;
    // Calculate the number of hours
    const hours =
      (BigInt(data.mttr_seconds) % SECONDS_IN_DAY) / SECONDS_IN_HOUR;

    // Calculate the number of minutes
    const minutes =
      (BigInt(data.mttr_seconds) % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE;

    return {
      ...acc,
      [data.severity]: {
        days: Number(days),
        hours: Number(hours),
        minutes: Number(minutes),
      },
    };
  }, defaultData);
}

function parseAssetsGrades(entries: MetricsEntries): Array<ChartData> {
  const theme = useTheme();
  const assetsGradesMetrics = getBaseMetrics<PolicyGrade>(
    entries,
    Metrics.SecurityAssetsStats,
  );

  return assetsGradesMetrics.map((gradeMetrics) => {
    const grade = gradeMetrics.grade.toUpperCase();

    if (grade === "ERRORED") {
      return {
        label: "ERROR",
        value: gradeMetrics.count,
        color: getColor(theme, "error"),
      };
    }

    return {
      label: grade,
      value: gradeMetrics.count,
      color: getColor(theme, grade),
    };
  });
}

function parsePassedCheckStats(entries: MetricsEntries): Array<GridData> {
  const checksPassedStats = getBaseMetrics<CheckResultType>(
    entries,
    Metrics.ChecksResultDistribution,
  );

  return checksPassedStats
    .filter((c) => c.severity !== "none")
    .map((c) => ({
      title: c.severity,
      score: c.passing,
      total: c.total,
    }));
}

export function parseMetrics(entries: MetricsEntries) {
  return {
    policyCategories: parsePolicyCategories(entries),
    policyGrades: parsePolicyGrades(entries),
    checksResults: parseChecksResults(entries),
    vulnerableAssetsSeverity: parseVulnerableAssetsSeverity(entries),
    advisoriesSeverity: parseAdvisoriesSeverity(entries),
    cvesSeverity: parseCVEsSeverity(entries),
    remediationSeverity: parseRemediationSeverity(entries),
    mttrSeverity: parseMTTRSeverity(entries),
    assetsGrades: parseAssetsGrades(entries),
    passedChecks: parsePassedCheckStats(entries),
  };
}
